import React from 'react';
import { PageLayout } from '../components/pageLayout';
import { Flex, Heading, Text, Box, css, Image } from '@chakra-ui/core';
import { graphql } from 'gatsby';
import { BlogPreviewPanel } from '../components/BlogPreviewPanel';
import { CTALink } from '../components/ctaLink';
import { FeatureImageHeader } from '../components/FeatureImageHeader';
import { Container } from '../components/Container';

interface Props {}

export const query = graphql`
	query {
		prismic {
			# Fed into BlogPreviewPanel
			allBlog_posts(first: 7) {
				edges {
					node {
						title
						cover_photo
						sub_title
						author
						_linkType
						_meta {
							uid
							lastPublicationDate
						}
					}
				}
			}
		}
	}
`;

const blog = ({ data }: Props) => {
	const prismicContent = data.prismic!;
	let blogPosts = prismicContent.allBlog_posts!.edges;
	// console.log(blogPosts);

	let transformedBlogPosts = blogPosts.map(({ node }) => ({
		author: node.author,
		bodyPreview: node.sub_title[0].text,
		img: node.cover_photo!.url,
		title: node.title[0].text,
		link: '/blog/' + node._meta.uid,
	}));

	let mostRecent = transformedBlogPosts[0];
	let remaining = transformedBlogPosts[0];

	return (
		<PageLayout>
			<Flex flexDir="column">
				<Box w="100">
					<FeatureImageHeader
						title={mostRecent.title}
						bodyText={mostRecent.bodyPreview}
						ctaLink={mostRecent.link}
						featureImageUrl={mostRecent.img}
					/>
					<Container>
						<BlogPreviewPanel posts={transformedBlogPosts} />
					</Container>
				</Box>
			</Flex>
		</PageLayout>
	);
};

export default blog;
